.nav {
  background-color: #111;
  position: sticky;
  top: 0;
  width: 100%;
  height: 70px;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid #00acee;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-itm {
  /* display: inline; */
  margin: 20px;
  color: white;
  transition: 0.3s ease all;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  /*font-family: 'Muli', sans-serif;*/
}

.nav-items .nav-itm:hover {
  color: #00acee;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s ease all;
}

.nav-itm > .active {
  text-decoration: none;
  color: #00acee;
  transition: 0.3s ease all;
}

.alinks {
    color: white;
    -webkit-transition: color 0.3s;
    -moz-transition:    color 0.3s;
    -ms-transition:     color 0.3s;
    -o-transition:      color 0.3s;
    transition:         color 0.3s;
}

.alinks:hover {
  text-decoration: none;
  color: #00acee;
  cursor: pointer;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,172,238, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
  
.custom-toggler.navbar-toggler {
    border-color: rgb(0,172,238);
} 

.homepage-container {
  height: 100vh;
  /*
  background: #0F2027;
  background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027);
  background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027);
  */
  /*
  background-color: #000000;
background-image: linear-gradient(147deg, #000000 0%, #2D3436 74%);
*/
  background-color: #2b4162;
  background-image: linear-gradient(315deg, #2b4162 0%, #12100e 74%);

  text-align: center;
  padding: 10px;
}

.homepage-container h1 {
  font-family: 'Work Sans', sans-serif;
  color: white;
}

.homepage-container span {
  color: #00acee;
}

.homepage-container .text-content {
  padding-top: 17%;
}

.homepage-container .particles {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.homepage-container .more-button {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  width: 200px;
  height: 50px;
  margin: 30px;
  padding: 10px;
  border: 3px solid white;
  color: white;
  background: transparent;
  transition: 0.3s ease;
}

.homepage-container .more-button:hover {
  color: white;
  cursor: pointer;
  background: #00acee;
  border: 3px solid #00acee;
  transition: 0.3s ease;
}

.about-container {
  padding: 30px;
  background: #FAFAFA;
}

.about-title {
  text-align: center;
  color: black;
  font-size: 55px;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.characteristics-container {
  width: 100%;
  text-align: center;
  align-items: center;
  margin-bottom: 60px;
}

.about-pic-skills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
  width: 92%;
}

.about-picture {
  grid-row: 1;
  grid-column: 1;
  width: 300px;
  height: auto;
  margin-top: 10px;
  /* border-radius: 8px; */
  border-radius: 50%;
  border: 1px solid grey;
  box-shadow: 5px 5px 5px grey;
}

.about-pic-skills-container a {
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  align-items: center;
  font-family: 'Muli', sans-serif;
  font-size: 20px;
  color: black;
  text-decoration: none;
  opacity: 0.8;
  border: 1px solid black;
  padding: 10px 33px;
  border-radius: 25px;
  transition: 0.3s ease;
}

.about-pic-skills-container a:hover {
  color: white;
  background: #00acee;
  border: 1px solid #00acee;
  transition: 0.3s ease;
}

.about-bio {
  margin: 1.3em 5em;
}

.projects-container {
  padding-top: 30px;
  background: #FAFAFA;
}


.projects-title {
  text-align: center;
  color: black;
  font-size: 55px;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

 
.project-comp-title {
  margin-bottom: 0;
  font-family: 'Muli', sans-serif;
  font-size: 30px;
}

.project-comp-title span {
  color: grey;
  font-size: 22px;
}

.project-comp-madeWith {
  font-family: 'Muli', sans-serif;
  font-size: 15px;
  color: grey;
}

.project-comp-img {
    /*
  margin: 0 50px;
  height: auto; 
  */
  width: 100%;
  object-fit: cover;
  height: auto;
  border-radius: 8px;
  border: 1px solid grey;
  box-shadow: 5px 5px 5px grey;
}

.project-comp-text {
  font-size: 19px;
  font-family: "Open Sans", sans-serif;
}

.project-comp-links a {
  font-family: 'Muli', sans-serif;
  font-size: 15px;
  text-decoration: none;
  color: black;
  opacity: 0.8;
  border: 1px solid black;
  padding: 5px 30px;
  border-radius: 20px;
  transition: 0.3s ease;
  margin-left: 50px;
}

.project-comp-links a:hover {
  background: #00acee;
  transition: 0.3s ease;
  color: white;
  border: 1px solid #00acee;
}

.separator {
  opacity: 0;
  margin: 5vh; 
}

svg {
  display: block;
  width: 100%;
  height: 90px;
}

.contact-container {
  text-align: center;
  background-color: #1b1b1c;
  /* padding-top: 20px; */
}

.contact-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.contact-text {
  font-size: 25px;
}

.notes-container {
    padding-top: 30px;
    background: #FAFAFA;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.notes-col {
    margin: 0 3%;
}

.notes-col li{
    font-size: 15px;
}

.notes-col span {
    color: #7d7d7d;
}

footer {
  background: black;
  border-top: 3px solid #00acee;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: auto;
}

.footerButton {
  font-size: 40px;
  width: 200px;
  margin: 60px;
  padding: 0;
  border: 3px solid white;
  color: white;
  border-radius: 0;
  background: transparent;
  transition: 0.3s ease;
}

.footerButton:hover {
  color: white;
  cursor: pointer;
  background: #00acee;
  border: 3px solid #00acee;
  transition: 0.3s ease;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin: auto;
  background: black;
}

.c--anim-btn span {
  color: white;
  text-decoration: none;
  text-align: center;
  display: block;
}

.c--anim-btn, .c-anim-btn {
    transition: 0.3s;     
}

.c--anim-btn {
    height: 60px;
    font: normal normal 700 2em/2em Arial,sans-serif;
    width: 60px;
    overflow: hidden;
    border-radius: 5px;
}

.c-anim-btn {
    margin-top: 0em;
}

.c--anim-btn:hover {
    background: #00acee;
}

.c--anim-btn:hover .c-anim-btn{
    margin-top: -2em;
}

.social-links i {
    color: white;
}

@media screen and (max-width: 850px) {
  .nav-item {
    font-size: 8px;
    margin: 6px;
  }
  .homepage-container .text-content {
    padding-top: 80px;
  } 

  .homepage-container h1 {
    font-size: 15px;
  }

  .homepage-container .more-button {
    font-size: 13px;
    width: 165px;
  }

  .about-pic-skills-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }  

  .about-bio {
      margin: 1.3em 2em;
  }

  .project-comp-title {
    font-size: 20px;
  } 

  .project-comp-title span {
    color: grey;
    font-size: 15px;
  } 

  .project-comp-madeWith {
    font-size: 15px;
  }

  .project-comp-text {
    font-size: 15px;
  }

  .separator {
    margin-bottom: 1vh;
  }

  .contact-text {
    font-size: 15px;
  }

  .social-links {
    width: 80%;
    margin: auto;
    background: black;
  }

  .c--anim-btn {
    height: 40px;
    font: normal normal 700 2em/1.35em Arial,sans-serif;
    width: 40px;
    overflow: hidden;
    border-radius: 5px;
  }
  .c--anim-btn:hover .c-anim-btn{
    margin-top: -1.35em;
  }

  .about-title {
    font-size: 40px;
  }

  .projects-title {
    font-size: 40px;
  }

  .contact-container {
    padding: 1px;
  }
  .contact-title {
    font-size: 40px;
  }

}